import { LogsEvent } from '@datadog/browser-logs';

let prevError: LogsEvent['error'] | null = null;
const timestampMap = new Map<string, number>();
const HOUR = 3600000;

const checkUnwantedErrorReports: Array<(event: LogsEvent) => boolean> = [
  // Auth0 Popup opened timeout
  (event) => event.error?.error === 'timeout',

  // Auth0 silent access_token re-issue expired, user re-login is needed
  (event) => event.error?.error === 'login_required',

  // Auth0 token issue interruption by the user
  (event) => event.message.includes('Error: Login required'),

  // Auth0 popup is closed by the user
  (event) => event.message.includes('Error: Popup closed'),

  // ws-chat rejection when previous error was login_required, i.e. no valid token
  (event) => prevError?.error === 'login_required' && event.message.includes('xhr poll error'),
];

export const skipUnwanted = (event: LogsEvent) => {
  if (event.error) {
    prevError = event.error;
    return checkUnwantedErrorReports.some((check) => check(event));
  }
};

export const shouldThrottleReport = (key: string) => {
  if (!timestampMap.has(key)) {
    timestampMap.set(key, Date.now());
    return false;
  }

  if (timestampMap.get(key)! + HOUR > Date.now()) {
    timestampMap.delete(key);
  }

  return true;
};

export const getEventKey = (event: LogsEvent): string => {
  return event.error?.message || event.message;
};
